@import "../components/index";
@import "../containers/index";
@import url("https://use.typekit.net/dbj7mto.css");

/* Patch For Syncfusion
    If the syncfusion license key is invalid or subscription is unavailable,
    this library will show a syncfusion subscription marketing overlay interrepting the UX
    This patch in CSS is to hide that overlay
    Note:- This will impact any UI div elements with `z-index: 999999999` or 'z-index: 99999'
*/
div[style*='z-index: 999999999'] {
  display: none;
}

div[style*='z-index: 99999'] {
  display: none;
}

.swal2-container {
  z-index: 1700;
}

.ag-dnd-ghost {
  z-index: 2000 !important;
}

// body.e-prevent-select {
//   top: 0;
//   position: relative;
// }
